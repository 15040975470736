import { Card, Row, Col, Input, Button, Spin, Tooltip } from "antd";
import "./index.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { LoadingOutlined, CheckCircleFilled } from "@ant-design/icons";
import { useWallet } from "../../store/wallet-context";
import { useZbcPool } from "../../store/zbc-pool-context";
import { useBackend } from "../../store/backend";
import { useInvitationCenter } from "../../store/invitation-center-context";
import { formatAmount } from "../../utils/utils";
import { useContract, useProvider, useSigner } from "wagmi";
import {
  NameserviceKeys,
  useNameservice,
} from "../../store/nameservice-context";
import zbcAbi from "../../abi/ZBCPoolInterface";
import { useTranslation } from "react-i18next";
import { waitForTransaction } from "@wagmi/core";
import invitationCenterABI from "../../abi/InvitationCenterInterface";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SubmitStake: React.FC = function () {
  const { t } = useTranslation();
  const wallet = useWallet();
  const ZbcPool = useZbcPool();
  const backend = useBackend();
  const invitationCenter = useInvitationCenter();
  const myZbcAmount = wallet.nativeBalance?.value || ethers.constants.Zero;
  const provider = useProvider();
  let [symbol, setSymbol] = useState("");

  // @ts-ignore
  let ver = window._zbc_app_ver;

  useEffect(() => {
    if (provider?.chains) {
      setSymbol(provider?.chains[0]?.nativeCurrency.symbol || "");
    }
  }, [provider.chains]);

  const [prepareStake, setPrepareStake] = useState(false);

  const [insufficient, setInsufficient] = useState(false);

  const [planIndex, setPlanIndex] = useState(0);

  const ns = useNameservice();
  const zbc = useContract({
    address: ns?.[NameserviceKeys.ZbcPool] || ethers.constants.AddressZero,
    abi: zbcAbi,
    signerOrProvider: provider,
  });

  function clipMaxAmount(input: BigNumber) {
    try {
      let maxStakeAmount = BigNumber.from(wallet.nativeBalance?.value).sub(
        ethers.utils.parseEther("10")
      );
      let stakeLimit = ethers.utils.parseEther("88888888");

      maxStakeAmount = maxStakeAmount.gte(stakeLimit)
        ? stakeLimit
        : maxStakeAmount;

      if (maxStakeAmount.lte(0)) maxStakeAmount = ethers.utils.parseEther("0");

      if (input.gte(maxStakeAmount)) {
        return formatAmount(ethers.utils.formatEther(maxStakeAmount));
      } else {
        return ethers.utils.formatEther(input);
      }
    } catch (error) {
      return "0";
    }
  }
  useEffect(() => {
    try {
      if (
        BigNumber.from(wallet.nativeBalance?.value)
          .sub(ethers.utils.parseEther("11"))
          .lte(ethers.utils.parseEther("0"))
      ) {
        setInsufficient(true);
      } else {
        setInsufficient(false);
      }
    } catch (error) {
      setInsufficient(true);
    }
  }, [wallet.nativeBalance]);

  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  const _code = params.get("code") || "";
  const _default_plan = params.get("plan") || "";

  const [invitationCode, setInvitationCode] = useState(_code);
  const [planPeriod, setPlanPeriod] = useState("");
  const [parmaCode, setparmaCode] = useState("");
  const [defaultPlan, setDefaultPlan] = useState("");
  if (_code !== parmaCode) {
    setparmaCode(_code);
  }
  if (_default_plan !== defaultPlan) {
    setDefaultPlan(_default_plan);
  }

  const PERCENT_OF_AMOUNT: Record<string, string> = {
    "25": formatAmount(
      ethers.utils.parseEther(clipMaxAmount(myZbcAmount)).mul(25).div(100)
    ),
    "50": formatAmount(
      ethers.utils.parseEther(clipMaxAmount(myZbcAmount)).mul(50).div(100)
    ),
    "75": formatAmount(
      ethers.utils.parseEther(clipMaxAmount(myZbcAmount)).mul(75).div(100)
    ),
    "100": clipMaxAmount(myZbcAmount),
  };

  const [stakeAmount, setStakeAmount] = useState("");

  function checkStakeAmount(e: React.ChangeEvent<HTMLInputElement>) {
    if (wallet.accountAddress) {
      const ZBC_DECIMALS = 18;
      const {
        target: { value: newValue },
      } = e;
      if (newValue.length === 0) {
        setStakeAmount("");
        return;
      }
      try {
        ethers.utils.parseUnits(newValue, ZBC_DECIMALS);

        let maxStakeAmount = BigNumber.from(wallet.nativeBalance?.value).sub(
          ethers.utils.parseEther("10")
        );
        let stakeLimit = ethers.utils.parseEther("88888888");

        maxStakeAmount = maxStakeAmount.gte(stakeLimit)
          ? stakeLimit
          : maxStakeAmount;

        if (maxStakeAmount.lte(0))
          maxStakeAmount = ethers.utils.parseEther("0");
        if (ethers.utils.parseEther(newValue).gte(maxStakeAmount)) {
          setStakeAmount(ethers.utils.formatEther(maxStakeAmount));
        } else if (parseFloat(newValue) < 0) {
          setStakeAmount("");
        } else {
          setStakeAmount(newValue);
        }
      } catch (error) {
        // console.log(error);
        setStakeAmount(stakeAmount);
      }
    }
  }

  const backend_ref = useRef(backend);
  backend_ref.current = backend;

  // const [logs, setLogs] = useState<any[]>([]);

  const newStake = async () => {
    const backend = backend_ref.current;
    console.log("start get params from server");
    setPrepareStake(true);
    const { signature, price } = backend.ZbcPriceRaw;
    try {
      if (ver === "u") {
        if (!backend.ready) {
          console.log("fetch data now!");
          setTimeout(() => {
            backend.fetchData();
            newStake();
          }, 5000);
          return false;
        }
      }
      // if can't get config,one day is one day

      const oneDay: any = ZbcPool?.globalConfig?.oneDay;
      if (!oneDay) {
        throw new Error("empty global config !");
      }
      const lockTimespan = oneDay * parseInt(planPeriod.valueOf());
      const code = (() => {
        if (invitationCenter.isRegistered) return ethers.constants.HashZero;
        if (inviteCodeStatus === "checked")
          return ethers.utils.formatBytes32String(invitationCode);
        if (invitationCode.length === 0)
          return invitationCenter.rootInviter?.invitationCode;
        throw new Error("invitation Code error");
      })();

      let args: any = [
        ethers.utils.parseEther(stakeAmount.valueOf()),
        ethers.BigNumber.from(lockTimespan),
        code,
      ];
      console.log("staking...");

      // console.log("root invite code: ",ethers.utils.parseBytes32String(invitationCenter.rootInviter?.invitationCode as `0x${string}`));

      // ver u
      if (ver === "u") {
        let gas =
          (await zbc?.estimateGas.stake(
            price,
            signature,
            args[0],
            args[1],
            args[2],
            {
              value: args[0],
              from: wallet.accountAddress,
            }
          )) || ethers.constants.Zero;

        //TODO: add some gas
        gas = gas.mul(120).div(100);
        args[0] = args[0].sub(gas);

        const hash = await ZbcPool.stakeWrite({
          recklesslySetUnpreparedArgs: [price, signature, ...args],
          recklesslySetUnpreparedOverrides: {
            value: args[0],
            gasLimit: gas.toString(),
          },
        });

        // console.log(hash);
        const data = await waitForTransaction({
          hash: hash.hash,
        });

        console.log("transaction end: ");

        console.log(data);
      } else {
        // ver z
        let gas =
          (await zbc?.estimateGas.stake(
            // price,
            // signature,
            args[0],
            args[1],
            args[2],
            {
              value: args[0],
              from: wallet.accountAddress,
            }
          )) || ethers.constants.Zero;

        //TODO: add some gas
        gas = gas.mul(120).div(100);
        // console.log("gas: ", gas.toNumber());

        // args[0] = args[0].sub(gas);

        const hash = await ZbcPool.stakeWrite({
          recklesslySetUnpreparedArgs: [...args],
          recklesslySetUnpreparedOverrides: {
            value: args[0],
            gasLimit: gas,
          },
        });

        console.log("hash: ");

        console.log(hash);
        // const data = await waitForTransaction({
        //   hash: hash.hash,
        // });

        console.log("transaction end: ");

        // console.log(data);
      }
    } catch (error: any) {
      // TODO: remove debug dialog when publish
      // ZbcPool.modal.error({
      //   title: "Transaction unsuccessful",
      //   content: error.message,
      // });
      console.log(error.message);
    }
    setPrepareStake(false);
  };

  let data = {
    apy: "--",
    DailyRate: "--",
    EstimatEarn: "--",
  };

  if (planPeriod.length && wallet.accountAddress) {
    const days = ZbcPool.plans[planIndex][0];
    const apy = ZbcPool.plans[planIndex][1];
    const percentOfYear = days / 365;
    data = {
      apy: apy + " %",
      DailyRate:
        formatAmount((apy * percentOfYear) / days, { precision: 6 }) + " %",
      EstimatEarn:
        formatAmount(
          ((apy * percentOfYear) / 100) * parseFloat(stakeAmount) || 0
        ) +
        " " +
        symbol,
    };
  }

  const { data: singer } = useSigner();

  const [inviteCodeStatus, setInviteCodeStatus] = useState("");
  const ic = useContract({
    address: ns?.[NameserviceKeys.InvitationCenter],
    abi: invitationCenterABI,
    signerOrProvider: singer,
  });
  const checkInviterCode = useCallback(
    async (e: any) => {
      setInvitationCode(e.target.value);
      setInviteCodeStatus("loading");
      const code = e.target.value;
      if (code.length === 8) {
        try {
          const inviter = await ic?.inviterCodeToAddress(
            ethers.utils.formatBytes32String(code) as `0x${string}`
          );
          if (inviter === ethers.constants.AddressZero) {
            setInviteCodeStatus("false");
          } else {
            setInviteCodeStatus("checked");
          }
        } catch (error) {
          console.log(error);
          setInviteCodeStatus("");
        }
      } else {
        if (code.length === 0) {
          setInviteCodeStatus("");
        } else {
          setInviteCodeStatus("false");
        }
      }
    },
    [ic]
  );

  useEffect(() => {
    if (parmaCode) {
      const e = {
        target: {
          value: parmaCode,
        },
      };
      checkInviterCode(e);
    }
    if (defaultPlan) {
      // 18 days
      if (defaultPlan === "1") {
        setPlanPeriod(ZbcPool.plans[0][0] + "");
        setPlanIndex(0);
      }
      // 88 days
      if (defaultPlan === "2") {
        setPlanPeriod(ZbcPool.plans[1][0] + "");
        setPlanIndex(1);
      }
      // 188 days
      if (defaultPlan === "3") {
        setPlanPeriod(ZbcPool.plans[2][0] + "");
        setPlanIndex(2);
      }
      // 888 days
      if (defaultPlan === "4") {
        setPlanPeriod(ZbcPool.plans[3][0] + "");
        setPlanIndex(3);
      }
    }
  }, [ZbcPool.plans, checkInviterCode, defaultPlan, parmaCode]);

  return (
    <Card className="stake-com" bordered={false} title={t("Stake")}>
      <Row justify="space-between">
        <Col>{t("Balance :")} </Col>
        <Col className="as-tb">
          <span style={{ paddingRight: "5px" }}>
            {formatAmount(myZbcAmount)}
          </span>
          <span>{symbol}</span>
          {/* <Tooltip title="We keep a portion of your balance for the processing fee. So you can't stake all the amounts">
            <QuestionCircleOutlined
              size={28}
              style={{ color: "#B3B3B3", paddingLeft: "5px" }}
            />
          </Tooltip> */}
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Input
          placeholder={`Stake Amount in ${symbol}`}
          value={stakeAmount}
          disabled={!wallet.accountAddress || insufficient}
          status={
            stakeAmount.length === 0 || parseFloat(stakeAmount) === 0
              ? "error"
              : ""
          }
          onChange={(e) => checkStakeAmount(e)}
          suffix={
            ver === "u" ? (
              <>
                {backend.ready ? (
                  <span>
                    ≈${" "}
                    {formatAmount(
                      backend.ZbcPriceFormated * parseFloat(stakeAmount || "0")
                    )}
                  </span>
                ) : (
                  <Spin indicator={antIcon} />
                )}
              </>
            ) : (
              <></>
            )
          }
          size="large"
        />
      </Row>
      <Row
        style={{
          display: insufficient && wallet.accountAddress ? "block" : "none",
          color: "red",
        }}
      >
        <Col>
          <p>{`Insufficient ${symbol} balance for gas`}</p>
        </Col>
      </Row>
      <Row style={{ padding: "20px 0 10px 0" }} gutter={10}>
        {["25", "50", "75", "100"].map((percent) => (
          <Col key={"p-" + percent} span={6}>
            <Button
              disabled={!wallet.accountAddress || insufficient}
              onClick={() => setStakeAmount(PERCENT_OF_AMOUNT[percent])}
              type={
                stakeAmount === PERCENT_OF_AMOUNT[percent]
                  ? "primary"
                  : "default"
              }
              block
            >
              <span>{percent}</span> <span>%</span>
            </Button>
          </Col>
        ))}
      </Row>
      <Row
        justify="space-between"
        style={{
          display:
            invitationCenter &&
            !invitationCenter?.isRegistered &&
            wallet.accountAddress
              ? "block"
              : "none",
        }}
      >
        <Col span={24}>{t("invitation_code")}</Col>
      </Row>
      <Row
        style={{
          display:
            invitationCenter &&
            !invitationCenter?.isRegistered &&
            wallet.accountAddress
              ? "block"
              : "none",
        }}
      >
        <Input
          status={inviteCodeStatus === "false" ? "error" : ""}
          onChange={(e) => checkInviterCode(e)}
          value={invitationCode}
          suffix={
            <span>
              {(() => {
                if (inviteCodeStatus === "false") return t("invalid_code");
                if (inviteCodeStatus === "loading")
                  return <Spin indicator={antIcon} />;
                if (inviteCodeStatus === "checked")
                  return <CheckCircleFilled style={{ color: "green" }} />;
                return "";
              })()}
            </span>
          }
          size="large"
          placeholder="Invitation code (Optional)"
        />
      </Row>
      <Row justify="space-between">
        <Col>{t("Plan :")}</Col>
      </Row>
      <Row style={{ marginBottom: "-16px" }} gutter={10}>
        {ZbcPool.plans.map((e: any, i: number) => (
          <Col
            key={"plan-" + e[0]}
            span={6}
            xs={12}
            sm={6}
            lg={6}
            xl={6}
            xxl={6}
            style={{ marginBottom: "16px" }}
          >
            <Button
              disabled={e[3]}
              loading={e[3]}
              key={e[0]}
              onClick={() => {
                setPlanPeriod(e[0] + "");
                setPlanIndex(i);
              }}
              block
              className="plan-btn"
              type={planPeriod === e[0] + "" ? "primary" : "default"}
            >
              <span>{`${e[0]} Days`}</span> <i>{e[1]} % APY</i>{" "}
            </Button>
          </Col>
        ))}
      </Row>
      <Row
        className="as-tb"
        style={{ paddingTop: "20px" }}
        justify="space-between"
      >
        <Col>{t("APY")}</Col>
        <Col>{data.apy}</Col>
      </Row>
      <Row className="as-tb" justify="space-between">
        <Col>Daily Rate</Col>
        <Col>{data.DailyRate}</Col>
      </Row>
      <Row className="as-tb" justify="space-between">
        <Col>Estimate Earn</Col>
        <Col>{data.EstimatEarn}</Col>
      </Row>
      <Row></Row>
      <Row style={{ paddingTop: "30px" }} justify="center">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {/* TODO: transtale this */}
          <Tooltip
            trigger={
              !planPeriod.length ||
              (invitationCode.length !== 8 &&
                invitationCode.length !== 0 &&
                !invitationCenter.isRegistered) ||
              !wallet.accountAddress ||
              !stakeAmount.length ||
              ethers.utils.parseEther(stakeAmount || "0").eq(0)
                ? ["hover", "click"]
                : []
            }
            title={
              <Col>
                <p
                  style={{
                    display: !wallet.accountAddress ? "block" : "none",
                  }}
                >
                  Please connect to your wallet
                </p>
                <p style={{ display: planPeriod.length ? "none" : "block" }}>
                  Please choose a plan
                </p>
                {/* <p
                  style={{
                    display:
                      !stakeAmount.length ||
                      ethers.utils.parseEther(stakeAmount || "0").eq(0)
                        ? "block"
                        : "none",
                  }}
                >
                  The amount needs to be greater than 0
                </p> */}
                {/* <p
                  style={{
                    display:
                      invitationCode.length !== 0 &&
                      inviteCodeStatus === "checked"
                        ? "block"
                        : "none",
                  }}
                >
                  {t("invalid_code")}
                </p> */}
              </Col>
            }
          >
            <Button
              disabled={
                !ZbcPool?.stakeWrite ||
                !stakeAmount.length ||
                ethers.utils.parseEther(stakeAmount || "0").eq(0) ||
                ethers.utils
                  .parseEther(wallet.nativeBalance?.formatted || "0")
                  .eq(0) ||
                !planPeriod.length ||
                inviteCodeStatus === "false"
              }
              onClick={newStake}
              block
              type="primary"
              size="large"
              loading={prepareStake || inviteCodeStatus === "loading"}
            >
              {t("Stake")}
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <div
        style={{ display: ZbcPool?.stakeWriteIsLoading ? "block" : "none" }}
        className="trade-spin"
      >
        <Spin size="large" />
      </div>
    </Card>
  );
};

export default React.memo(SubmitStake);
// export default SubmitStake
